<template>
  <v-app>
    <DashboardCoreView />
    <div>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-divider />
          <h2 style="text-align: center">LLamada entrante: {{ numero }}</h2>
          <v-divider />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" large fab @click="rechazarLlamada()">
              <v-icon>mdi-phone-hangup</v-icon>
            </v-btn>
            <v-btn large fab color="success" @click="aceptarLlamada()">
              <v-icon>mdi-phone</v-icon>
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Drawer />
  </v-app>
</template>
<script>
export default {
  name: "DashboardIndex",
  components: {
    Drawer: () => import("./drawer"),
    DashboardCoreView: () => import("./View"),
    colgarBoton: () => import("./phone/colgarBoton"),
  },
  data: () => ({
    dialog: false,
    numero: "",
  }),
  methods: {
    rechazarLlamada() {
      var myInstance = this.$store.getters.getinstanciaPhone;
      myInstance.declineCall();
      this.$store.dispatch("setllamadaEntranteVentanaTime", false);
      this.$store.dispatch("setnombreLLamadaEntrante", "");

      // this.$store.commit("SET_NOMBRELLAMADAENTRANTE", "");
      //  this.$store.commit("SET_VERCOLGAR", false);

      this.$store.dispatch("setverColgar", false);
      this.dialog = false;
    },
    aceptarLlamada() {
      //  this.$store.commit("SET_VERCOLGAR", true);
      this.$store.dispatch("setverColgar", true);

      this.$store.getters.getinstanciaPhone.acceptCall();
      this.llamadaEntranteVentanaTime = true;
      this.$store.dispatch("setllamadaEntranteVentanaTime", true);

      this.$store.dispatch("setiniciarBotonera", true);

      // this.$store.commit("SET_INICIARBOTONERA", true);
      this.dialog = false;
      this.$router.push("/PhoneLlamar").catch((err) => {});
    },
  },
  computed: {
    VincommingCall() {
      return this.$store.getters.getnombreLLamadaEntrante;
    },
    VLlamadaEstado() {
      return this.$store.getters.getllamadaEstado;
    },
  },
  watch: {
    VLlamadaEstado(value) {
      if (value == "hangup") {
        this.dialog = false;
        this.$store.dispatch("setllamadaEntranteVentanaTime", false);
      }
    },
    VincommingCall(value) {
      if (value != "") {
        this.verBoton = false;
        if (value.includes(":") && value.includes("@")) {
          value = value.split(":")[1].split("@")[0];
        }
        this.numero = value;
        this.$notification.show(
          "Llamada entrante",
          {
            body: this.numero,
          },
          {}
        );
        this.$store.dispatch("setnombreLLamadaEntrante", this.numero);

        // this.$store.commit("SET_NOMBRELLAMADAENTRANTE", this.numero);
        this.nombreLLamadaEntrante = value;
        this.llamadaEntranteVentana = true;

        if (this.dialog) {
          this.aceptarLlamada();
          this.$store.dispatch("setnombreLLamadaEntrante", this.numero);
        } else {
          this.dialog = true;
        }

        this.$store.dispatch("setllamadaEstado", "intermedio");
      }
    },
  },
};
</script>



